import React from 'react';
import { observer, inject } from 'mobx-react';
import i18n from "../i18n";
import getAgentInstance from '../helpers/superagent';

const superagent = getAgentInstance(true);
import {
  Button, Select,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Tooltip,
  Checkbox,
  notification
} from 'antd';

import {
  NotificationOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import RemoteSelect from './basic/RemoteSelect';

class WhatsappSender extends React.Component {
  constructor(props) {
    super(props);
    this.defaultMessageTemplate = '';
    this.initialState = () => ({
      isModalOpen: false,
      sendingMessage: false,
      selectedWhatsappFile: null,
      selectedExcelFile: null,
      sendType: 'chat',
      contactType: 'Social Media',
      all: false,
    });
    this.state = this.initialState();
    this.modelFormRef = React.createRef();
    this.openModal = () => {
      this.setState({
        isModalOpen: true,
      });
    };

    this.closeModal = () => {
      this.setState({
        isModalOpen: false,
      });
    };

    this.contactsRef = React.createRef();

    this.sendWhatsappMessage = async (values) => {
      try {
        await this.setState({
          sendingMessage: true,
        });
        const form = new FormData();
        form.append('message', values.text);
        form.append('instanceName', process.env.REACT_APP_WHATSAPP_SERVICE_NAME);
        form.append('whatsappFile', this.state.selectedWhatsappFile);
        form.append('sendType', this.state.sendType);
        form.append('contactType', values.contactType);
        form.append('allContactOfType', this.state.all);
        form.append('contactsIds', !this.state.all ? values.contacts.map((c) => c.value) : []);

        await superagent
          .post('/whatsappService/sendMessage')
          .send(form);
          notification.success({
            message: 'Successfully Imported',
            placement: 'bottomRight',
        });
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        await this.setState({
          sendingMessage: false,
        });
      }
    };

    this.whatsappFileChanged = (e) => {
      const file = e.target.files[0];
      let type = 'chat';
      const fileType = file.type;
      if (fileType.includes('image')) type = 'image';
      else if (fileType.includes('video')) type = 'video';
      else type = 'document';
      this.setState({
        selectedWhatsappFile: file,
        sendType: type,
      });
    };

    this.contactTypeChanged = (type) => {
      this.setState({
        contactType: type,
      }, () => {
        if (this.contactsRef.current) {
          this.contactsRef.current.loadData();
        }
      });
    };
  }

  render() {
    const { isModalOpen, sendingMessage, all } = this.state;

    return (
      <>
        <Col span={12}>
          <Tooltip color="black" title={i18n.t('Send Whatsapp Notification')}>
            <Button
              shape="circle"
              onClick={this.openModal}
              loading={sendingMessage}
              icon={<WhatsAppOutlined />}
              style={{ marginTop: '15px' }}
              className="btn_hover btn_hover_edit"
            />
          </Tooltip>
        </Col>
        {isModalOpen
                && (
                <Modal
                  loading={sendingMessage}
                  visible={isModalOpen}
                  okButtonProps={{
                    form: 'modelForm',
                    htmlType: 'submit',
                    className: 'darkBlueBg',
                    loading: sendingMessage,
                  }}
                  onCancel={this.closeModal}
                  width="60%"
                >
                  <Form
                    id="modelForm"
                    ref={this.modelFormRef}
                    onFinish={this.sendWhatsappMessage}
                    style={{ marginTop: '5%' }}
                    initialValues={{
                      text: this.defaultMessageTemplate,
                    }}
                  >

                    <Row gutter={10}>
                      <Col span={22}>
                        <Form.Item
                          label={i18n.t('Contact Type')}
                          name="contactType"
                          initialValue="Social Media"
                        >
                          <Select allowClear={false} onChange={this.contactTypeChanged}>
                            <Select.Option value="Company and Organization">
                              {i18n.t('Company and Organization')}
                            </Select.Option>
                            <Select.Option value="Person">
                              {i18n.t('Person')}
                            </Select.Option>
                            <Select.Option value="Investor">
                              {i18n.t('Investor')}
                            </Select.Option>
                            <Select.Option value="Supplier">
                              {i18n.t('Supplier')}
                            </Select.Option>
                            <Select.Option value="Share Holder">
                              {i18n.t('Share Holder')}
                            </Select.Option>
                            <Select.Option value="Social Media">
                              {i18n.t('Social Media')}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          label={i18n.t('All')}
                          name="all"
                        >
                          <Checkbox onChange={(val) => this.setState({
                            all: val.target.checked,
                          })}
                          />
                        </Form.Item>

                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={i18n.t(' Select Contact')}
                          name="contacts"
                          rules={!all ? [
                            {
                              required: true,
                              message: i18n.t('input is required'),
                            },
                          ] : []}
                        >
                          <RemoteSelect
                            ref={this.contactsRef}
                            mode="multiple"
                            allowClear={false}
                            endpoint={`/contact/list?type=${this.state.contactType}`}
                            className="sms-multiple-selector"
                            optiontext={(ob) => `${ob.name}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="whatsappFile"
                          label={i18n.t('File')}
                          labelAlign="left"
                        >
                          <Input type="file" onChange={this.whatsappFileChanged} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="text"
                          label={i18n.t('Text')}
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                              message: i18n.t('Input is required'),
                            },
                          ]}
                        >
                          <Input.TextArea rows={10} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <a href={`${process.env.REACT_APP_API_LINK}/whatsappExcel.xlsx`} target="_blank">{i18n.t('Download excel template')}</a>
                </Modal>
                )}
      </>
    );
  }
}

export default WhatsappSender;
