/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyAta1YBtv7VbPUCS8BKxluODoFOg-ql8is',
  authDomain: 'mpc-iraq.firebaseapp.com',
  projectId: 'mpc-iraq',
  storageBucket: 'mpc-iraq.appspot.com',
  messagingSenderId: '1010241709857',
  appId: '1:1010241709857:web:ece8fdc309373f79ad0060',
  measurementId: 'G-7HJK0WQVY9',
};

const app = initializeApp(config);
export const authentication = getAuth(app);
