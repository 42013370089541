/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import {
  Layout,
  Menu,
  Button,
  Avatar,
  Dropdown,
  Modal,
  Col,
  Row,
} from 'antd';

import {
  TagOutlined,
  TeamOutlined,
  UserOutlined,
  DownOutlined,
  HomeOutlined,
  BankOutlined,
  AuditOutlined,
  MessageOutlined,
  FileTextOutlined,
  SnippetsOutlined,
  FileSyncOutlined,
  PoweroffOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  ContainerOutlined,
  ApartmentOutlined,
  LineChartOutlined,
  PercentageOutlined,
  CalculatorOutlined,
  MenuUnfoldOutlined,
  RedEnvelopeOutlined,
  DollarCircleOutlined,
  NotificationOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  UsergroupDeleteOutlined,
  KeyOutlined,
  WhatsAppOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import store from '../../store';
import Loading from '../basic/Loading';
import i18n from '../../i18n';
import routes from './dashboardRoutes';
import getAgentInstance from '../../helpers/superagent';
import largeLogo from '../../assets/images/icons/lucid-bg-logo.svg';
import smalLogo from '../../assets/images/icons/lucid-login-logo.svg';
import NotificationsBell from '../notifications/notificationsBell';

import './dashboard.css';
import WhatsappSender from '../WhatsappSender';

const superagent = getAgentInstance();
const {
  Header, Content, Footer, Sider,
} = Layout;

@inject('userStore', 'tokenStore', 'sectionsStore', 'notification')
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      loading: true,
      collapsed: false,
      defualtMenu: 'visitlist',
      UIFlags: {},
      access: JSON.parse(this.props.userStore.value.privilege),
      brandingLogo: '',
      brandingColor: '',
      countRent: 0,
      countSale: 0,
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { userStore, tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => {});
    };

    this.warning = () => {
      const scope = this;
      Modal.confirm({
        centered: true,
        title: i18n.t('Logout Of The System?'),
        content: '',
        okText: i18n.t('Yes'),
        cancelText: i18n.t('No'),
        onOk() {
          scope.logout();
        },
        onCancel() {},
      });
    };

    this.fetchCounter = () => {
      superagent.get('/debtpending/countme').end((err, res) => {
        if (!err) {
          const { body } = res;
          this.setState({
            // countRent: body.countRent,
            // countSale: body.countSale,
          });
          store.notification.PaymentValue = body.countRent;
          store.notification.EndingValue = body.countSale;
        }
      });
    };

    this.fetchBrandingLogo = () => {
      const { userStore } = this.props;
      superagent.get('/design/?field=branding_logo').end((error, response) => {
        if (!error) {
          const design = response.body.value;
          userStore.value.brandingLogo = design;
          this.setState({ brandingLogo: design });
        }
      });
    };

    this.fetchBrandingColor = () => {
      const { userStore } = this.props;
      superagent.get('/design/?field=branding_color').end((error, response) => {
        if (!error) {
          const design = response.body.value;
          userStore.value.brandingColor = design;
          this.setState({ brandingColor: design });
          window.less.modifyVars({
            '@primary-color':
              this.state.brandingColor !== ''
                ? this.state.brandingColor
                : '#65DFC2',
          });
        }
      });
    };
  }

  componentDidMount() {
    const { userStore } = this.props;
    if (userStore.value.privilege === undefined) {
      this.setState({ access: {} });
    }
    const scope = this;
    superagent.get('/user/me').end((err, res) => {
      if (!err) {
        const { body } = res;
        userStore.value = body;
      } else if (err && err.status === 401) {
        Modal.error({
          centered: true,
          title: 'Unauthorised!',
          content: 'This account is unauthorised, You need to logout!',
          okText: 'Procced',
          onOk() {
            scope.logout();
          },
        });
      }
      this.setState({ loading: false });
      this.fetchCounter();
    });

    this.fetchBrandingLogo();
    this.fetchBrandingColor();
  }

  render() {
    const { location, userStore, history } = this.props;
    const {
      collapsed,
      loading,
      access,
      brandingLogo,
      brandingColor,
    } = this.state;
    const { pathname } = location;
    const { value } = userStore;

    let sectionName;
    if (pathname === '/') {
      sectionName = i18n.t('Dashboard');
    } else if (pathname === '/unit-list') {
      sectionName = i18n.t('Unit');
    } else if (pathname === '/reports') {
      sectionName = i18n.t('Reports');
    } else if (pathname === '/unit-type') {
      sectionName = i18n.t('Unit Type');
    } else if (pathname === '/project') {
      sectionName = i18n.t('Project');
    } else if (pathname === '/contact-list') {
      sectionName = i18n.t('Contact');
    } else if (pathname === '/contact-person-type') {
      sectionName = i18n.t('Contact Person Type');
    } else if (pathname === '/invoice') {
      sectionName = i18n.t('Invoice');
    } else if (pathname === '/receipt') {
      sectionName = i18n.t('Receipt');
    } else if (pathname === '/receipt-delete') {
      sectionName = i18n.t('Delete Receipt');
    } else if (pathname === '/rent-notification') {
      sectionName = i18n.t('Rent Notification');
    } else if (pathname === '/sale-notification') {
      sectionName = i18n.t('Sale Notification');
    } else if (pathname === '/payment-notification') {
      sectionName = i18n.t('Payment Notification');
    } else if (pathname === '/ending-contract-notification') {
      sectionName = i18n.t('Ending Contract Notification');
    } else if (pathname === '/offer') {
      sectionName = i18n.t('Offer');
    } else if (pathname === '/account-statement') {
      sectionName = i18n.t('Account Statement');
    } else if (pathname === '/investor') {
      sectionName = i18n.t('Investor Account');
    } else if (pathname === '/investor-payment') {
      sectionName = i18n.t('Investor Payment');
    } else if (pathname === '/investor-transaction') {
      sectionName = i18n.t('Investor Transaction');
    } else if (pathname === '/sms') {
      sectionName = i18n.t('SMS');
    } else if (pathname === '/sale-contract') {
      sectionName = i18n.t('Sale Contracts');
    } else if (pathname === '/rent-contract') {
      sectionName = i18n.t('Rent Contracts');
    } else if (pathname === '/cancel-contracts') {
      sectionName = i18n.t('Cancel');
    } else if (pathname === '/my-profile') {
      sectionName = i18n.t('My Profile');
    } else if (pathname === '/user-profile') {
      sectionName = i18n.t('User Profile');
    } else if (pathname === '/safe') {
      sectionName = i18n.t('Safe');
    } else if (pathname === '/tasks') {
      sectionName = i18n.t('Tasks');
    }

    const menu = (
      <Menu style={{ top: '10px' }}>
        <Menu.Item
          onClick={() => history.push('/my-profile')}
          icon={(
            <UserOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('My Profile')}
        </Menu.Item>
        {access['View User'] && (
          <Menu.Item
            onClick={() => history.push('/user-profile')}
            icon={(
              <TeamOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('User Profile')}
          </Menu.Item>
        )}
        {access['View Branch'] && (
          <Menu.Item
            onClick={() => history.push('/branches')}
            icon={(
              <TeamOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('Branches')}
          </Menu.Item>
        )}
        {access['View Role'] && access['Edit Role'] && access['Add New Role'] && (
          <Menu.Item
            onClick={() => history.push('/roles')}
            icon={(
              <KeyOutlined
                style={{
                  color: brandingColor !== '' ? brandingColor : '#65DFC2',
                }}
              />
            )}
          >
            {i18n.t('Roles')}
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          onClick={this.warning}
          icon={(
            <PoweroffOutlined
              style={{
                color: brandingColor !== '' ? brandingColor : '#65DFC2',
              }}
            />
          )}
        >
          {i18n.t('Logout')}
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <style>
          {`

          .sider_toggler_btn {
            position: static !important;
          top: 0 !important;
          left: 0px !important;
          z-index: 2;
          }

          .sider_menu_links.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item:hover {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item-selected::before {
              content: '';
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: ${brandingColor};
          }
        `}
        </style>
        <div
          style={{
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: !loading ? 'none' : 'flex',
          }}
        >
          <Loading visible={loading} tip={i18n.t('Loading User Data...')} />
        </div>
        <Layout
          style={{
            minHeight: '100vh',
            display: loading ? 'none' : undefined,
          }}
        >
          <Sider
            theme="light"
            className="layout_sider"
            width={240}
            collapsed={collapsed}
            style={{
              height: '100vh',
              left: i18n.language === 'en' ? 0 : null,
              right: i18n.language !== 'en' ? 0 : null,
              position: 'fixed',
              overflow: 'auto',
            }}
          >
            <div className={collapsed ? 'logo_small' : 'logo'}>
              {collapsed ? (
                <img
                  alt="lucid-logo"
                  style={{ width: 65 }}
                  src={brandingLogo !== '' ? brandingLogo : smalLogo}
                />
              ) : (
                <img
                  alt="lucid-logo"
                  style={{ width: 185 }}
                  src={brandingLogo !== '' ? brandingLogo : largeLogo}
                />
              )}
            </div>
            <Menu
              mode="inline"
              className="sider_menu_links"
              selectedKeys={[location.pathname]}
            >
              {access['View Dashboard'] && (
                <Menu.Item key="/">
                  <LineChartOutlined />
                  <span>{i18n.t('Dashboard')}</span>
                  <Link to="/" />
                </Menu.Item>
              )}

              {(access['View Project']
                || access['View Unit']
                || access['View Offer']) && (
                <Menu.SubMenu
                  key={2}
                  title={i18n.t('Unit Info')}
                  icon={<HomeOutlined />}
                >
                  {access['View Unit'] && (
                    <Menu.Item key="/unit-list">
                      <UnorderedListOutlined />
                      <span>{i18n.t('Unit Definition')}</span>
                      <Link to="/unit-list" />
                    </Menu.Item>
                  )}
                  {access['Accept Offer'] && (
                    <Menu.Item key="/pending_units">
                      <PercentageOutlined />
                      <span>{i18n.t('Pending Units')}</span>
                      <Link to="/pending_units" />
                    </Menu.Item>
                  )}
                  {access['View Offer'] && (
                    <Menu.Item key="/offer">
                      <PercentageOutlined />
                      <span>{i18n.t('Offered Units')}</span>
                      <Link to="/offer" />
                    </Menu.Item>
                  )}
                  {access['View Project'] && (
                    <Menu.Item key="/project">
                      <ContainerOutlined />
                      <span>{i18n.t('Projects')}</span>
                      <Link to="/project" />
                    </Menu.Item>
                  )}
                  {access['View Unit'] && (
                    <Menu.Item key="/unit-type">
                      <ApartmentOutlined />
                      <span>{i18n.t('Unit Type')}</span>
                      <Link to="/unit-type" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access['View Contract']
                || access['View Archive Contracts']) && (
                <Menu.SubMenu
                  key={6}
                  title={i18n.t('Contracts')}
                  icon={<SnippetsOutlined />}
                >
                  {access['View Contract'] && (
                    <Menu.Item key="/sale-contract">
                      <TagOutlined />
                      <span>{i18n.t('Sale Contracts')}</span>
                      <Link to="/sale-contract" />
                    </Menu.Item>
                  )}
                  {access['View Contract'] && (
                    <Menu.Item key="/draft-contract">
                      <TagOutlined />
                      <span>{i18n.t('Draft Contracts')}</span>
                      <Link to="/draft-contract" />
                    </Menu.Item>
                  )}
                  {access['View Contract'] && (
                    <Menu.Item key="/rent-contract">
                      <FileSyncOutlined />
                      <span>{i18n.t('Rent Contracts')}</span>
                      <Link to="/rent-contract" />
                    </Menu.Item>
                  )}
                  <Menu.Item key="/external-contracts">
                    <FileTextOutlined />
                    <span>{i18n.t('External Contracts')}</span>
                    <Link to="/external-contracts" />
                  </Menu.Item>
                  {access['View Archive Contracts'] && (
                    <Menu.Item key="/cancel-contracts">
                      <AuditOutlined />
                      <span>{i18n.t('Cancel Contracts')}</span>
                      <Link to="/cancel-contracts" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access['View Invoice']
                || access['View Receipt']
                || access['View Expense']
                || access['View Account Statement']) && (
                <Menu.SubMenu
                  key={4}
                  title={i18n.t('Accounting')}
                  icon={<ReconciliationOutlined />}
                >
                  {access['View Safe'] && (
                    <Menu.Item key="/safe">
                      <BankOutlined />
                      <span>{i18n.t('Safe')}</span>
                      <Link to="/safe" />
                    </Menu.Item>
                  )}
                  {access['View Invoice'] && (
                    <Menu.Item key="/invoice">
                      <DollarCircleOutlined />
                      <span>{i18n.t('Invoice')}</span>
                      <Link to="/invoice" />
                    </Menu.Item>
                  )}
                  {access['View Receipt'] && (
                    <Menu.Item key="/receipt">
                      <RedEnvelopeOutlined />
                      <span>{i18n.t('Voucher')}</span>
                      <Link to="/receipt" />
                    </Menu.Item>
                  )}
                  {access['View Receipt'] && (
                    <Menu.Item key="/receipt-delete">
                      <AuditOutlined />
                      <span>{i18n.t('Delete Receipt')}</span>
                      <Link to="/receipt-delete" />
                    </Menu.Item>
                  )}
                  {access['View Account Statement'] && (
                    <Menu.Item key="/account-statement">
                      <CalculatorOutlined />
                      <span>{i18n.t('Account Statement')}</span>
                      <Link to="/account-statement" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {(access['View Contact']
                || access['View Receipt']
                || access['View Expense']
                || access['View Account Statement']) && (
                <Menu.SubMenu
                  key={5}
                  title={i18n.t('CRM')}
                  icon={<ReconciliationOutlined />}
                >
                  {access['View Contact'] && (
                    <Menu.Item key="/contact-list">
                      <UsergroupDeleteOutlined />
                      <span>{i18n.t('Contact')}</span>
                      <Link to="/contact-list" />
                    </Menu.Item>
                  )}

                  {access['Send Whatsapp'] && (
                    <Menu.Item key="/whatsapp">
                      <WhatsAppOutlined />
                      <span>{i18n.t('Whatsapp')}</span>
                      <Link to="/whatsapp" />
                    </Menu.Item>
                  )}
                  <Menu.Item key="/tasks">
                    <ScheduleOutlined />
                    <span>{i18n.t('Tasks')}</span>
                    <Link to="/tasks" />
                  </Menu.Item>
                  {access['View Holiday'] && (
                    <Menu.Item key="/holidays">
                      <ScheduleOutlined />
                      <span>{i18n.t('Holidays')}</span>
                      <Link to="/holidays" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              )}

              {/* {(access['View Investor']
                || access['View Investor Payment']
                || access['View Investor Contract']) && (
                  <Menu.SubMenu
                    key={5}
                    title={i18n.t('Investors')}
                    icon={<TeamOutlined />}
                  >
                    {access['View Investor Payment'] && (
                      <Menu.Item key="/investor-payment">
                        <UserAddOutlined />
                        <span>{i18n.t('Investor Payment')}</span>
                        <Link to="/investor-payment" />
                      </Menu.Item>
                    )}
                    {access['View Investor Contract'] && (
                      <Menu.Item key="/investor-transaction">
                        <UserSwitchOutlined />
                        <span>{i18n.t('Investor Transaction')}</span>
                        <Link to="/investor-transaction" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
              )} */}
              {access['View Reports'] && (
                <Menu.Item key="/reports">
                  <PieChartOutlined />
                  <span>{i18n.t('Reports')}</span>
                  <Link to="/reports" />
                </Menu.Item>
              )}
              {access['Read Notifications'] && (
                <Menu.Item key="/notifications">
                  <NotificationOutlined />
                  <span>{i18n.t('Notifications')}</span>
                  <Link to="/notifications" />
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          {i18n.language === 'en' ? (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginLeft: collapsed === false ? 240 : 80,
                marginRight: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col>
                  <div className="sider_toggler_btn">
                    <Button
                      type="link"
                      icon={
                        collapsed ? (
                          <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                        ) : (
                          <MenuFoldOutlined style={{ color: '#1f2532' }} />
                        )
                      }
                      onClick={this.toggleSidebar}
                      style={{
                        textAlign: 'start',
                        paddingLeft: 10,
                        paddingRight: undefined,
                      }}
                    />
                  </div>
                </Col>
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 0]}>
                      <Col>
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                              >
                                English
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                              >
                                سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ba');
                                  window.location.reload();
                                }}
                                key="3"
                              >
                                بادینی
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'en' ? 'English' : null}
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {i18n.language === 'sr' ? 'سۆرانی' : null}
                            {i18n.language === 'ba' ? 'بادینی' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>

                      <Col>{access['Send Whatsapp'] && <WhatsappSender />}</Col>

                      <Col>
                        {access['View SMS'] && (
                          <Button
                            title={i18n.t('SMS')}
                            shape="circle"
                            icon={<MessageOutlined />}
                            onClick={() => history.push('/sms')}
                            style={{
                              backgroundColor: '#EAECF2',
                              border: 'none',
                            }}
                          />
                        )}
                      </Col>
                      {access['Read Notifications'] && (
                        <Col>
                          <NotificationsBell />
                        </Col>
                      )}
                      <Col>
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col>
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginLeft: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>
              <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <span>{i18n.t('Designed and Developed By')}</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://lucid-source.com"
                  title="Lucid Source"
                >
                  {i18n.t('Lucid Source')}
                </a>
              </Footer>
            </Layout>
          ) : (
            <Layout
              className="site-layout"
              style={{
                transition: 'all 0.3s',
                marginRight: collapsed === false ? 240 : 80,
                marginLeft: 0,
              }}
            >
              <Header
                className="site-layout-background"
                style={{ padding: 0, display: 'inherit', position: 'relative' }}
              >
                <Col
                  span={1}
                  style={{
                    textAlign: 'start',
                    paddingLeft: undefined,
                    paddingRight: 10,
                  }}
                >
                  <Button
                    type="link"
                    icon={
                      collapsed ? (
                        <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                      ) : (
                        <MenuFoldOutlined style={{ color: '#1f2532' }} />
                      )
                    }
                    onClick={this.toggleSidebar}
                  />
                </Col>
                {/* <div className="sider_toggler_btn">
                    <Button
                      type="link"
                      icon={
                        collapsed ? <MenuUnfoldOutlined style={{ color: '#1f2532' }} />
                          : <MenuFoldOutlined style={{ color: '#1f2532' }} />
                      }
                      onClick={this.toggleSidebar}
                    />
                  </div> */}
                <div className="main_header_container">
                  <div className="section_title">{sectionName || ''}</div>
                  <div className="header_avatar">
                    <Row gutter={[10, 0]}>
                      <Col
                        style={{
                          width: 'initial !important',
                        }}
                      >
                        <Dropdown
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('en');
                                  window.location.reload();
                                }}
                                key="1"
                              >
                                English
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ar');
                                  window.location.reload();
                                }}
                                key="4"
                              >
                                عربي
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('sr');
                                  window.location.reload();
                                }}
                                key="2"
                              >
                                سۆرانی
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  i18n.changeLanguage('ba');
                                  window.location.reload();
                                }}
                                key="3"
                              >
                                بادینی
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button className="br-10_h-40" type="default">
                            {i18n.language === 'en' ? 'English' : null}
                            {i18n.language === 'ar' ? 'العربية' : null}
                            {i18n.language === 'sr' ? 'سۆرانی' : null}
                            {i18n.language === 'ba' ? 'بادینی' : null}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                      <Col
                        style={{
                          width: 'unset !important',
                        }}
                      >
                        {access['Send Whatsapp'] && <WhatsappSender />}
                      </Col>

                      <Col
                        style={{
                          width: 'unset !important',
                        }}
                      >
                        {access['View SMS'] && (
                          <Button
                            title={i18n.t('SMS')}
                            shape="circle"
                            icon={<MessageOutlined />}
                            onClick={() => history.push('/sms')}
                            style={{
                              backgroundColor: '#EAECF2',
                              border: 'none',
                            }}
                          />
                        )}
                      </Col>

                      {access['Read Notifications'] && (
                        <Col
                          style={{
                            width: 'unset !important',
                          }}
                        >
                          <NotificationsBell />
                        </Col>
                      )}

                      <Col
                        style={{
                          width: 'unset !important',
                        }}
                      >
                        <Avatar shape="square" icon={<UserOutlined />} />
                      </Col>
                      <Col
                        style={{
                          marginLeft: '10px',
                          width: 'unset !important',
                        }}
                      >
                        <Dropdown
                          overlay={menu}
                          trigger="click"
                          placement="bottomRight"
                        >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                            {' '}
                            <DownOutlined style={{ marginRight: '5px' }} />
                          </span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Header>
              <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
                <div
                  className="site-layout-background"
                  style={{
                    padding: '8px 8px 0',
                    minHeight: 500,
                    backgroundColor: 'transparent',
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {routes.map((route) => (
                        <Route
                          exact={route.exact}
                          key={route.key}
                          path={`${route.path}`}
                        >
                          <route.component />
                        </Route>
                      ))}
                    </Switch>
                  </Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <span>{i18n.t('Designed and Developed By')}</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://lucid-source.com"
                  title="Lucid Source"
                >
                  {i18n.t('Lucid Source')}
                </a>
              </Footer>
            </Layout>
          )}
        </Layout>
      </>
    );
  }
}

export default withTranslation()(
  withRouter((props) => <Dashboard {...props} />),
);
