import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Menu,
  Dropdown,
  Select,
  Card,
  notification,
} from "antd";
import {
  LockFilled,
  DownOutlined,
  RightOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { RecaptchaVerifier } from "firebase/auth";

import i18n from "../../i18n";
import getAgentInstance from "../../helpers/superagent";
import { authentication } from "../../firebase/firebase";

const { Option } = Select;
const superagent = getAgentInstance();

@inject("tokenStore", "sectionsStore", "userStore")
@observer
class Login extends React.Component {
  constructor() {
    super();
    this.recaptchaVerifier = null;
    this.initialState = () => ({
      loading: false,
      isUserValid: false,
      formValues: undefined,
    });
    this.state = this.initialState();

    this.onFinish = () => {
      this.setState({ loading: true });
      this.recaptchaVerifier.verify().catch((error) => {
        console.error('Recaptcha verification failed:', error);
        this.setState({ loading: false });
      });
    };

    this.sendAuthFirstRequest = () => {
      const formValues = this.formRef.current.getFieldValue();
      const phoneNo = `964${formValues.phone_no.replace(/\s/g, "")}`;

      const firstData = {
        phone_no: parseInt(phoneNo, 10),
        client: "mpc_iraq",
        passcode: formValues.passcode,
      };

      superagent
        .post(`${process.env.REACT_APP_API_LINK}/auth/first`)
        .send(firstData)
        .end((err) => {
          if (!err) {
            this.setState(
              {
                formValues: {
                  phone_no: phoneNo,
                  passcode: formValues.passcode,
                  client: "mpc_iraq",
                },
              },
              () => {
                this.setState({ isUserValid: true, loading: false });
              },
            );
          } else this.setState({ loading: false });
        });
    };

    this.verifyOtp = () => {
      const otp = this.formRef.current.getFieldValue(["code"]);
      this.setState({ otpLoadingBtn: true });
      if (otp.length === 6) {
        const { formValues } = this.state; // Use the formValues from the state
        const otpData = {
          phone_no: formValues.phone_no,
          client: 'mpc_iraq',
          passcode: formValues.passcode,
          otp: parseInt(otp, 10), // Ensure otp is sent as a number
        };

        superagent
          .post(`${process.env.REACT_APP_API_LINK}/auth/second`)
          .send(otpData)
          .then((res) => {
            this.onVerified(res);
          })
          .catch((err) => {
            // Handle error
            notification.error({
              message: 'Verification Failed!',
              description: `Something went wrong, ${err.message}`,
              placement: 'bottomRight',
            });
            this.setState({ otpLoadingBtn: false });
          });
      } else {
        // Handle invalid OTP length
        notification.error({
          message: 'Invalid Input!',
          description: 'Input length should contain a 6 digit code!',
          placement: 'bottomRight',
        });
        this.setState({ otpLoadingBtn: false });
      }
    };

    this.onVerified = (res) => {
      const { body } = res;
      const { tokenStore, sectionsStore, userStore } = this.props;

      if (body.success) {
        userStore.value = body.data.user;
        tokenStore.value = body.data.token;
        sectionsStore.value = body.data.sections;
        message.success({ content: 'Successfully logged in' });
        window.location.reload();
      } else {
        message.error({ content: body.msg });
      }

      this.setState({ loading: false });
    };

    this.onGoBack = () => {
      window.location.reload();
    };

    this.handleKeyDown = (event) => {
      if (event.key === "Enter") {
        this.onFinish();
      }
    };

    this.handleVerifyOtpKeyDown = (event) => {
      if (event.key === "Enter") {
        this.verifyOtp();
      }
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (i18n.language === 'en-GB' || i18n.language === 'en-US') {
      i18n.changeLanguage('en');
      window.location.reload();
    }

    this.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          this.sendAuthFirstRequest();
        },
      },
      authentication,
    );
    this.recaptchaVerifier.render();
  }

  render() {
    const { loading, isUserValid, otpLoadingBtn } = this.state;

    const menu = (
      <Menu style={{ minWidth: "120px" }}>
        <Menu.Item
          onClick={() => i18n.changeLanguage("en")}
          icon={<RightOutlined style={{ color: "#9A9A9A" }} />}
        >
          {i18n.t("English")}
        </Menu.Item>
        <Menu.Item
          onClick={() => i18n.changeLanguage("sr")}
          icon={<RightOutlined style={{ color: "#9A9A9A" }} />}
        >
          {i18n.t("Kurdish-Sorani")}
        </Menu.Item>
        <Menu.Item
          onClick={() => i18n.changeLanguage("ba")}
          icon={<RightOutlined style={{ color: "#9A9A9A" }} />}
        >
          {i18n.t("Kurdish-Badini")}
        </Menu.Item>
        <Menu.Item
          onClick={() => i18n.changeLanguage("ar")}
          icon={<RightOutlined style={{ color: "#9A9A9A" }} />}
        >
          {i18n.t("Arabic")}
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <Card className="login-card font-calss">
          <Form onFinish={this.onFinish} layout="vertical" ref={this.formRef}>
            <Row justify="center" gutter={[0, 10]}>
              <Col>
                <p className="login-card-title font-calss">
                  {i18n.t("MPC Iraq")}
                </p>
              </Col>
            </Row>
            <Row justify="center" gutter={[0, 64]}>
              <Col>
                <p className="login-card-subtitle font-calss">
                  {i18n.t(
                    "Access and manage your instances from this MPC Iraq account"
                  )}
                </p>
              </Col>
            </Row>
            {!isUserValid ? (
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="phone_no"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      className="Input_with_addBefores"
                    >
                      {i18n.language === "en" ? (
                        <Input
                          className="login_phone_input"
                          placeholder={i18n.t("Phone")}
                          addonBefore={
                            <Select defaultValue="964">
                              <Option value="964">+964</Option>
                            </Select>
                          }
                        />
                      ) : (
                        <Input
                          className="login_phone_input"
                          placeholder={i18n.t("Phone")}
                          addonAfter={
                            <Select defaultValue="964">
                              <Option value="964">+964</Option>
                            </Select>
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Form.Item
                      name="passcode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password
                        className="br-10_h-60"
                        onKeyDown={this.handleKeyDown}
                        placeholder={i18n.t("Passcode")}
                        prefix={<LockFilled style={{ fontSize: "20px" }} />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className="font-calss"
                        style={{ height: "60px", borderRadius: "10px" }}
                      >
                        <p className="login-card-subtitle font-calss">
                          {i18n.t("Next")}
                        </p>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[0, 32]}>
                  <Col span={24}>
                    <Form.Item
                      name="code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        className="br-10_h-60"
                        onKeyDown={this.handleVerifyOtpKeyDown}
                        placeholder={i18n.t("Enter Verification Code")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        block
                        type="primary"
                        loading={otpLoadingBtn}
                        className="font-calss"
                        onClick={this.verifyOtp}
                        style={{ height: "60px", borderRadius: "10px" }}
                      >
                        <p className="login-card-subtitle font-calss">
                          {i18n.t("Verify")}
                        </p>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col span={isUserValid ? 6 : 0}>
                <Form.Item>
                  <Button
                    size="large"
                    type="text"
                    onClick={() => this.onGoBack()}
                    style={{ height: "20px", borderRadius: "10px" }}
                    className="font-calss"
                  >
                    {i18n.language === "en" ? (
                      <ArrowLeftOutlined />
                    ) : (
                      <ArrowRightOutlined />
                    )}{" "}
                    {i18n.t("Back")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 24]} justify="center">
              <Col className="center">
                <Dropdown
                  overlay={menu}
                  trigger="click"
                  placement="bottomCenter"
                >
                  <span className="ant-dropdown-link pointer">
                    {i18n.t("Languages")} <DownOutlined />
                  </span>
                </Dropdown>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <p className="login-card-subtitle font-calss">
                  {i18n.t("MPC Iraq by")}{" "}
                  <a
                    className="font-calss"
                    target="_blank"
                    rel="noreferrer"
                    href="https://lucid-source.com"
                    style={{ textDecoration: "underline" }}
                  >
                    {i18n.t("Lucid Source")}
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Card>
        <div id="recaptcha-container" />
      </>
    );
  }
}
export default withTranslation()(Login);
