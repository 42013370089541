const Pusher = require('pusher-js')
var subscribedChannels = []
var bindedEvents = []

var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
});

module.exports = {
    pusher,
    subscribe: (channelName) => {
        if (!subscribedChannels.find(sc => sc == channelName)) {
            subscribedChannels.push(channelName)
            pusher.subscribe(channelName)
        }
    },
    unsubscribe: (channelName) => {
        subscribedChannels = subscribedChannels.filter(sc => sc != channelName)
        pusher.unsubscribe(channelName)
    },
    bindEvent: (channelName, eventName, callback) => {
        const channelPlusEventNames = `${channelName}-${eventName}`
        if (!bindedEvents.find(be => be == channelPlusEventNames)) {
            bindedEvents.push(channelPlusEventNames)
            pusher.bind(eventName, callback)
        }
    },
    unbindEvent: (channelName, eventName) => {
        const channelPlusEventNames = `${channelName}-${eventName}`
        bindedEvents = bindedEvents.filter(be => be != channelPlusEventNames)
        pusher.unbind(eventName)
    },
}
