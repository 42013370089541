/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { BellOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  Avatar, Badge, Popover, List, Button,
} from 'antd';

import './notification.css';

import i18n from '../../i18n';
import getAgentInstance from '../../helpers/superagent';

const pusher = require('../../helpers/pusher');

const superagent = getAgentInstance();

@inject('userStore')
@observer
class NotificationsBell extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      notifications: [],
    });
    this.state = this.initialState();
    this.fetch = async () => {
      try {
        const data = await superagent.get('/notification/readN');
        this.setState({
          notifications: data.body,
        });
      } catch (error) {
        console.log(error);
        this.initialState();
      }
    };

    this.makeNotificationSeen = async (n) => {
      try {
        await superagent.post('/notification/makeSeen').send({
          notification_id: n.id,
        });

        this.props.history.push(`/${n.notificationType}?q=${n.objectId}`);

        this.setState((prevState) => ({
          notifications: prevState.notifications.map((notification) => {
            if (notification.id === n.id) {
              notification.seen = 1;
            }
            return notification;
          }),
        }));
      } catch (error) {
        console.log(error);
      }
    };

    // pusher functions
    this.notified = (notification) => {
      this.setState((prevState) => ({
        notifications: [notification, ...prevState.notifications],
      }));
    };

    this.notificationSeen = (notification) => {
      const { id } = notification;
      this.setState((prevState) => ({
        notifications: prevState.notifications.map((n) => {
          if (n.id === id) n.seen = 1;
          return n;
        }),
      }));
    };
  }

  componentDidMount() {
    this.fetch();
    const privileges = JSON.parse(this.props.userStore.value.privilege);
    pusher.subscribe('Notifications');
    if (privileges['Receive Contract Notification']) {
      pusher.bindEvent('Notifications', 'contractCreated', this.notified);
      pusher.bindEvent('Notifications', 'contractExpired', this.notified);
      pusher.bindEvent('Notifications', 'overDuePayment', this.notified);
    }
    if (privileges['Receive Invoice Notification']) {
      pusher.bindEvent('Notifications', 'invoiceCreated', this.notified);
      pusher.bindEvent('Notifications', 'invoiceSubmitted', this.notified);
    }
    pusher.bindEvent(
      'Notifications',
      'notificationSeen',
      this.notificationSeen,
    );
  }

  componentWillUnmount() {
    pusher.unsubscribe('Notifications');
    pusher.unbindEvent('Notifications', 'contractCreated');
    pusher.unbindEvent('Notifications', 'contractExpired');
    pusher.unbindEvent('Notifications', 'overDuePayment');
    pusher.unbindEvent('Notifications', 'invoiceCreated');
    pusher.unbindEvent('Notifications', 'invoiceSubmitted');
    pusher.unbindEvent('Notifications', 'notificationSeen');
  }

  render() {
    const { notifications } = this.state;
    const badgeCount = notifications.filter((n) => n.seen === 0).length;
    const notificationsContent = (
      <List
        dataSource={notifications}
        footer={(
          <Button
            type="link"
            href="/notifications"
            style={{
              width: '100%',
            }}
          >
            {i18n.t('Show More')}
          </Button>
        )}
        renderItem={(n) => (
          <List.Item
            onClick={() => this.makeNotificationSeen(n)}
            className="notificationBox"
          >
            <>
              {n[`text_${i18n.language}`]}
              {n.seen === 0 && (
                <InfoCircleFilled
                  style={{
                    color: '#977755',
                  }}
                />
              )}
            </>
          </List.Item>
        )}
      />
    );

    return (
      <>
        <Popover
          placement="bottom"
          trigger="click"
          content={notificationsContent}
        >
          <Badge
            count={badgeCount}
            style={{ zIndex: '99', marginRight: '10px' }}
          >
            <Avatar
              style={{
                background: '#EAECF2',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              icon={<BellOutlined style={{ color: '#000000' }} />}
            />
          </Badge>
        </Popover>
      </>
    );
  }
}

export default withTranslation()(withRouter(NotificationsBell));
